<template>
    <v-card>
        <v-card-title v-if="!hideHeader">
            <NavButton />
        </v-card-title>
        <v-divider v-if="!hideHeader" />
        <v-card-text>
            <FiltrosSticky icono="mdi-filter-settings" :modo-sticky="filtrosSticky">
                <template v-if="!esProveedor || esAdmin" v-slot:header-append>
                    <div class="d-flex flex-wrap align-center justify-sm-end mr-2" style="gap: 16px; max-width: 100%;">
                        <slot name="prepend-actions" :disabled="!filtros.id_institucion || !filtros.id_anio_fiscal" :anio-fiscal="filtros.id_anio_fiscal" />
                        <v-btn 
                            color="secondary" 
                            style="max-width: 220px;" 
                            large
                            :disabled="descargandoReportePac || !filtros.id_institucion || !filtros.id_anio_fiscal"
                            @click.stop="descargarReportePAC"
                        >
                            <v-icon color="white" class="mr-2">mdi-file-excel</v-icon>
                            Generar reporte
                          </v-btn>
                    </div>
                </template>
                <v-row>
                    <v-col v-if="!idInstitucion" cols="12" md="6" lg="3">
                        <InstitucionesAutoComplete
                            :value.sync="filtros.id_institucion"
                            :evento-reinicio="eventoRestablecerFiltros"
                            :listener="institucionesListener"
                            :autocomplete-props="{ disabled: instituciones.isLoading || descargandoReportePac }"
                            solo-instituciones-padre
                        />
                    </v-col>
                    <v-col cols="12" md="6" :lg="idInstitucion ? 4 :3">
                        <v-select
                            v-model="filtros.id_anio_fiscal"
                            outlined
                            hide-details
                            :items="aniosFiscales.data"
                            :loading="aniosFiscales.isLoading"
                            :disabled="instituciones.isLoading || descargandoReportePac"
                            item-value="id"
                            item-text="anio"
                            label="Año"
                            placeholder="Seleccione un año"
                        />
                    </v-col>
    
                    <v-col cols="12" md="6" :lg="idInstitucion ? 4 :3">
                        <v-autocomplete
                            v-model="filtros.id_forma_contratacion"
                            :items="modalidadesFiltradas"
                            :loading="cargandoModalidades"
                            :disabled="instituciones.isLoading || descargandoReportePac"
                            label="Modalidad de compra"
                            placeholder="Seleccione una modalidad"
                            item-value="id"
                            item-text="nombre"
                            outlined
                            clearable
                            hide-details
                        />
                    </v-col>
    
                    <v-col cols="12" :md="idInstitucion ? 12 : 6" :lg="idInstitucion ? 4 :3">
                        <div class="d-flex align-center justify-space-between" style="gap: 16px">
                            <v-btn
                                color="primary"
                                class="flex-grow-1 flex-shrink-1"
                                x-large
                                @click.stop="hacerBusquedaConFiltros"
                            >
                                Buscar
                            </v-btn>
    
                            <v-btn
                                color="primary"
                                class="flex-grow-1 flex-shrink-1"
                                outlined
                                x-large
                                @click.stop="restablecerInformacion"
                            >
                                Limpiar
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </FiltrosSticky>

            <MontosModalidades
                v-if="mostrarGraficos"
                class="mt-4"
                :evento-busqueda="eventoCargarEstadisticasMontos"
                evitar-pre-carga
                :listener="estadisticasMontosListener"
            />

            <DataTableComponent
                :headers="headers"
                :items="instituciones.data"
                :total_registros="registrosTotales"
                :show_loading="instituciones.isLoading"
                @paginar="manejarPaginacion"
                v-models:pagina="paginacion.pagina"
                v-models:select="paginacion.registrosPorPagina"
                class="mt-4"
                dense
                no-gutters
            >
                <template v-slot:item.monto_planificado="{ item }">
                    {{ toMoneyFormat(item.monto_planificado) }}
                </template>
                <template v-slot:item.acciones="{ item }">
                    <div class="d-flex align-center justify-center" style="gap: 8px">
                        <v-btn @click.stop="abrirModalPlanificacionMensual(item)" color="secondary" icon>
                            <v-icon>mdi-calendar-month</v-icon>
                        </v-btn>
                        <v-btn @click.stop="abrirModalPlanificacionPorModalidad(item)" color="secondary" icon>
                            <v-icon>mdi-folder-eye</v-icon>
                        </v-btn>
                    </div>
                </template>
            </DataTableComponent>
        </v-card-text>
    
        <ModalPlanificacionMensual 
            :is-open.sync="modalPlanificacionMensualAbierta" 
            :listener="modalPlanificacionMensualListener"
            :evento-busqueda="eventoCargarResumenMensual"
        />
    
        <ModalPlanificacionPorModalidad 
            :is-open.sync="modalPlanificacionPoModalidadAbierta"
            :listener="modalPlanificacionPorModalidadListener"
            :evento-carga="eventoCargarResumenPorModalidad"
        />
    </v-card>
</template>
<script>
import { createLoadable, createPageable, setLoadableResponse, setPageableResponse, toggleLoadable, togglePageable } from '@/utils/loadable';
import InstitucionesAutoComplete from '../compartidos/InstitucionesAutoComplete.vue';
import ModalPlanificacionPorModalidad from './ModalPlanificacionPorModalidad.vue';
import FiltrosSticky from '@/views/CmReporteria/components/FiltrosSticky.vue';
import ModalPlanificacionMensual from './ModalPlanificacionMensual.vue';
import DataTableComponent from '../DataTableComponent.vue';
import { Listener, Observable } from '@/utils/observable';
import NavButton from '../utils/NavButton.vue';
import { toMoneyFormat } from '@/utils/data';
import { ValidacionAccesos } from '@/utils/role-utils';
import MontosModalidades from '@/views/CmReporteria/modalidadesCompra/MontosModalidades.vue';

const filtrosIniciales = {
    id_anio_fiscal: null,
    id_institucion: null,
    id_forma_contratacion: null,
};

export default {
    name: 'PlanificacionMensualPAC',
    components: { FiltrosSticky, NavButton, InstitucionesAutoComplete, DataTableComponent, ModalPlanificacionMensual, ModalPlanificacionPorModalidad, MontosModalidades },
    props: {
        idInstitucion: { type: [Number, String] },
        filtrosSticky: { type: Boolean, default: true },
        hideHeader: { type: Boolean, default: false },
        mostrarGraficos: { type: Boolean, default: false },
    },
    data: () => ({
        // Filtros
        aniosFiscales: createLoadable([]),
        cargandoModalidades: false,
        modalidades: [],
        filtros: { ...filtrosIniciales },
        filtrosActivos: { ...filtrosIniciales },

        // UI
        descargandoReportePac: false,
        eventoRestablecerFiltros: "RESTABLECER_INFORMACION_REPORTERIA_PLANIFICACION_MENSUAL_PAC",
        eventoCargarResumenMensual: "CARGAR_RESUMEN_MENSUAL",
        eventoCargarResumenPorModalidad: "CARGAR_RESUMEN_POR_MODALIDAD",
        eventoCargarEstadisticasMontos: "CARGAR_ESTADISTICAS_MONTOS",
        institucionesListener: new Listener("InstitucionesListener"),
        modalPlanificacionMensualListener: new Listener("ModalPlanificacionMensualListener"),
        modalPlanificacionPorModalidadListener: new Listener("ModalPlanificacionPorMensualidadListener"),
        estadisticasMontosListener: new Listener("EstadisticasMontosListener"),
        modalPlanificacionMensualAbierta: false,
        modalPlanificacionPoModalidadAbierta: false,
        unsubscribers: [],

        // Data
        instituciones: createPageable([], 10),
        paginacion: {
            pagina: 1,
            registrosPorPagina: 10,
        },
    }),
    computed: {
        modalidadesFiltradas() {
            return this.modalidades.filter((modalidad) => ![18, 27].includes(modalidad.id));
        },
        headers() {
            return [
                { text: 'Institución', value: 'nombre', sortable: false, align: 'center' },
                { text: 'Monto', value: 'monto_planificado', sortable: false, align: 'center' },
                { text: 'Acciones', value: 'acciones', sortable: false, align: 'center' },
            ]
        },
        registrosTotales() {
            return this.instituciones.pagination.total_rows;
        },
        esProveedor() {
            return ValidacionAccesos.ES_PROVEEDOR;
        },
        esAdmin() {
            return ValidacionAccesos.ES_ADMIN;
        },
    },
    methods: {
        // UI
        toMoneyFormat,
        manejarPaginacion(paginacion) {
            const { pagina, cantidad_por_pagina: registrosPorPagina } = paginacion;
            this.paginacion = { pagina, registrosPorPagina };

            this.cargarResumenInstitucional();
        },
        restablecerInformacion() {
            this.filtros = { 
                ...this.filtros,
                id_institucion: null,
                id_forma_contratacion: null, 
            };

            if (this.idInstitucion) this.filtros.id_institucion = this.idInstitucion;

            this.filtrosActivos = { ...this.filtros };
            this.paginacion = { pagina: 1, registrosPorPagina: 10 };
            this.cargarResumenInstitucional();
            Observable.emit(this.eventoRestablecerFiltros);
            Observable.emit(this.eventoCargarEstadisticasMontos, { ...this.filtros });
        },
        hacerBusquedaConFiltros() {
            this.paginacion.pagina = 1;

            if (this.idInstitucion) this.filtros.id_institucion = this.idInstitucion;

            this.filtrosActivos = { ...this.filtros };

            Observable.emit(this.eventoCargarEstadisticasMontos, { ...this.filtros });
            this.cargarResumenInstitucional();
        },
        obtenerAnioActual(anios) {
            const anioActual = new Date().getFullYear();
            return anios.find((anio) => anio.anio === anioActual);
        },
        abrirModalPlanificacionMensual(institucion) {
            const anio = this.aniosFiscales.data.find((anio) => anio.id === this.filtrosActivos.id_anio_fiscal);

            this.modalPlanificacionMensualAbierta = true;
            Observable.emit(this.eventoCargarResumenMensual, { anio_fiscal: anio, institucion: institucion, modalidad: this.filtrosActivos.id_forma_contratacion });
        },
        abrirModalPlanificacionPorModalidad(institucion) {
            const anio = this.aniosFiscales.data.find((anio) => anio.id === this.filtrosActivos.id_anio_fiscal);

            this.modalPlanificacionPoModalidadAbierta = true;
            Observable.emit(this.eventoCargarResumenPorModalidad, { anio_fiscal: anio, institucion: institucion, modalidad: this.filtrosActivos.id_forma_contratacion });
        },
        // DATA
        async cargarAniosFiscales() {
            toggleLoadable(this.aniosFiscales);
            const { data } = await this.services.PacProcesos.cargarAniosFiscales();
            const anios = Array.isArray(data) ? data : [];
            setLoadableResponse(this.aniosFiscales, { data: anios });

            return anios;
        },
        async cargarModalidades() {
            try {
                this.cargandoModalidades = true;
                const response = await this.services.Paac.getModalidades({ compra_tradicional: true });
                this.modalidades = response.data?.forma_contratacion ?? [];
            } finally {
                this.cargandoModalidades = false;
            }
        },
        async cargarResumenInstitucional() {
            const filtros = {
                pagination: true,
                page: this.paginacion.pagina,
                per_page: this.paginacion.registrosPorPagina,
                ...this.filtrosActivos,
            };

            togglePageable(this.instituciones);
            const { data, headers } = await this.services.ReportePlanificacionPac.cargarResumenInstitucional(filtros);
            setPageableResponse(this.instituciones, data, headers);
        },
        async descargarReportePAC() {
            try {
                this.descargandoReportePac = true; 
                const anio = this.aniosFiscales.data.find((anio) => anio.id === this.filtros.id_anio_fiscal);

                const response = await this.services.PacServices.descargarReportePac(this.filtros.id_institucion, false, { anio_calendario: anio.anio });
    
                if (!response) {
                    this.pushAppMessage({  type: "error", message: "Ocurrió un error al descargar el documento" });
                    return;
                }
    
                const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const file = new File([blob], 'reporte-pac', { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(file);
                link.download = `ReportePAC-${anio.anio ?? ''}.xlsx`;
                link.click();
            } finally {
                this.descargandoReportePac = false;
            }
        },
    },
    created() {
        this.cargarAniosFiscales().then((anios) => {
            const anio = this.obtenerAnioActual(anios);
            this.filtros.id_anio_fiscal = anio?.id ?? null;
            this.hacerBusquedaConFiltros();
        });

        this.cargarModalidades();

        this.unsubscribers = [
            Observable.getInstance().subscribe( this.eventoRestablecerFiltros, this.institucionesListener),
            Observable.getInstance().subscribe( this.eventoCargarResumenMensual, this.modalPlanificacionMensualListener),
            Observable.getInstance().subscribe( this.eventoCargarResumenPorModalidad, this.modalPlanificacionPorModalidadListener),
            Observable.getInstance().subscribe( this.eventoCargarEstadisticasMontos, this.estadisticasMontosListener),
        ];
    },
    beforeDestroy() {
        this.unsubscribers.forEach((unsubscriber) => unsubscriber());
    },
}
</script>